<template>
  <v-dialog
    v-model="show"
    max-width="700px"
    scrollable
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          <b>{{ product.name }}</b>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading || loadingImages"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <v-row>
          <v-col
            v-if="images.length > 0"
            cols="12"
            md="6"
          >
            <v-row>
              <v-col>
                <v-skeleton-loader
                  v-if="loadingImages"
                  class="mx-auto"
                  type="image"
                />
                <v-img
                  v-if="!loadingImages && !isNaN(imageActive)"
                  :src="imageName(images[imageActive].image)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#3E4095"
                      />
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader
                      v-if="loadingImages"
                      class="mx-auto"
                      type="card-heading"
                    />
                  </v-col>
                  <v-slide-group
                    v-if="!loadingImages"
                    v-model="imageActive"
                    show-arrows
                    mandatory
                  >
                    <v-slide-item
                      v-for="image in images"
                      v-slot="{ toggle }"
                      :key="image.id"
                    >
                      <v-card
                        outlined
                        height="60"
                        width="75"
                        class="ma-1"
                        @click="toggle"
                      >
                        <v-card-text class="pa-0">
                          <v-img :src="imageName(image.image)">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0 my-auto"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  size="20"
                                  width="2"
                                  color="#3E4095"
                                />
                              </v-row>
                            </template>
                          </v-img>
                        </v-card-text>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h3 class="mb-4">
              {{ product.name }}
            </h3>
            <p>
              {{ product.description }}
            </p>

            <v-btn
              :href="`https://wa.me/5551997311319?text=Ol%C3%A1%2C%20estou%20interessado%20no%20produto%20${product.name}.%20Gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20ele.%20Obrigado!`"
              target="_blank"
              depressed
              dark
              small
              color="green"
              class="mb-3 mb-md-2"
            >
              <v-icon
                left
                color="white"
                v-text="'mdi-whatsapp'"
              />
              <b>Obtenha Mais Informações</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import productsApi from '@/api/products'
  import productsImagesApi from '@/api/products-images'

  export default {
    components: {},
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      productId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingImages: false,
        imageActive: null,
        product: {},
        images: [],
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
        this.loadImages()
      },
    },

    methods: {
      async load () {
        try {
          this.product = {}
          if (!this.productId) return

          this.loading = true
          const response = await productsApi.get(this.productId)
          this.product = response.data.product
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadImages () {
        try {
          this.images = []
          if (!this.productId) return

          this.loadingImages = true
          const response = await productsImagesApi.list(this.productId)
          this.images = response.data.images

          if (this.images.length > 0) {
            this.imageActive = 0
          }
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingImages = false
        }
      },

      imageName (name) {
        return `${process.env.VUE_APP_API}public/products/${name}`
      },
    },
  }
</script>
